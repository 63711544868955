<template>
  <div class="profile">
    <Tile z="1">
      <h2>Game Code</h2>
      <QRCode :gameId="$store.state.game.game_obj.publicId"></QRCode>
    </Tile>
    <Tile z="1">
      <h2>Observer Settings</h2>
      <InputCheckbox :default="this.$store.state.player.settings.streamingMode" :inputData="streamingMode" @update="updateStreamingMode($event);" placeholder="Streaming Mode"></InputCheckbox>
    </Tile>
  </div>
</template>
<script>
  import Tile from "@/components/Tile.vue";
  import InputCheckbox from "@/components/InputCheckbox.vue";
  import QRCode from "@/components/QRCode.vue";
  export default {
    name: 'Profile',
    components: {
      Tile,
      InputCheckbox,
      QRCode,
    },
    computed: {
        player: function() {
          return {}
        }
    },
    data: function() {
      return {
        streamingMode: false
      }
    },
    methods: {
      async updateStreamingMode(streamingMode) {
        this.streamingMode = streamingMode;
        
        this.$store.commit('set_player_settings', {
          streamingMode: this.streamingMode
        });
      },
    }
  }
</script>

<style scoped lang="less">

</style>