<template>
  <div class="InputCheckbox">
    <!-- Need all 3 of those for some weird android bug -->
    <input class="input--text corners--rounded" type="checkbox"
      v-on:input="inputCallBack"
      name="placeholder"
      value="box"
      v-model="inputData"
      :id="placeholder"
      @change="$emit('update', inputData);"/>
    <label :for="placeholder">{{placeholder}}</label>
    <div class="underRow">
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: [
    'placeholder',
    'default',
    'inputData',
  ],
  created: function() {
    if(this.default) {
      this.inputData = true;
    }
    this.$emit('update', this.default);
  },
  data: function() {
    return {
      checked: []
    }
  },
  computed: {
    value: function() {
      return this.inputData;
    }
  },
  methods: {
    inputCallBack: function() {
      //this.inputData = e.target.value;

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.InputCheckbox {

  @invalid-color: red;
  @valid-color: green;

  margin: auto;
  width: 250px;
  display: flex;
  flex-flow: row;
  align-items: center;


  & > * {
    cursor: pointer;
    color: white;
  }
  
  
  position: relative;

  input {
    background: @background-color-tertiary;
    min-height: 30px;
    max-height: 30px;
    min-width: 30px;
    max-width: 30px;
    font-size: 16px;

    margin: 0 0 0 0;
    padding: 0;

    outline: none;


    
    border-radius: 5px;
    
    -webkit-appearance: none;
    -moz-appearance: none;

    &:checked {
      
      &:after {
        content: '✕';
        margin:auto;
        position: absolute;
        top: -6px;
        left: 4px;
        font-weight: bolder;
        font-size: 26px;
        color: @app-color-primary-1;
      }
    }
    

    // &:checked:after {
    //   position: absolute;
      
    //   font-size: 40px;
    //   top: 0;
    //   left: 5px;
    // }
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    padding-left: 10px;
    height: 30px;
  }

  .underRow {
    margin-top:2px;
    font-size: .75em;
    width: 100%;

    .characterLimit {
      color: @font-color-placeholder;

      .reason {
        position: absolute;
        left: 0;
        text-align: left;
      }

      .lengthCount {
        position: absolute;
        right: 0;
      }

      &.invalid {
        color: @invalid-color;
      }
    }
  }
}
</style>
